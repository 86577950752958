import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import { isMobileOnly } from 'react-device-detect';
import InitAnimation from './init-animation';
import Style from '../styles/common/published.module.scss';

class PublishedKex extends React.Component {
  constructor(props) {
    super(props);
    this.transition = props.transition;
    this.initAnimation = new InitAnimation();
  }

  published = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      if (isMobileOnly) {
        this.initAnimation.publishedSpAnimation(this.visualSp, this.imgSp);
      } else {
        this.initAnimation.publishedPcAnimation(this.visualPc, this.imgPc);
      }
      unobserve();
    }
  };

  render() {
    const PublishedQuery = graphql`
      {
        MainVisualSp: file(relativePath: { eq: "works/kex/main_visual_sp.jpg" }){
          childImageSharp {
            fluid(maxWidth: 375, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        MainVisualPc: file(relativePath: { eq: "works/kex/main_visual_pc.jpg" }){
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        DeviceSp: file(relativePath: { eq: "works/kex/device_sp.png" }){
          childImageSharp {
            fluid(maxWidth: 170, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        DevicePc: file(relativePath: { eq: "works/kex/device_pc.png" }){
          childImageSharp {
            fluid(maxWidth: 715, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={PublishedQuery}
        render={(data) => (
          <Observer {...options} onChange={this.published}>
            <TransitionLink to="/works/kex" exit={{ length: this.transition.InitTransition.length, trigger: () => this.transition.animation() }} entry={{ delay: this.transition.InitTransition.delay }}>
              <div className={`published ${Style.published}`}>
                <div className={Style.publishedItem}>
                  <div
                    ref={(node) => { this.visualSp = node; }}
                    className={`spTabVDisBlock init_a_visualSp ${Style.visual}`}
                  >
                    <Img
                      className="init_a_imgSp"
                      fluid={data.MainVisualSp.childImageSharp.fluid}
                      alt="KYOTO EXPERIMENT"
                    />
                  </div>
                  <div
                    ref={(node) => { this.visualPc = node; }}
                    className={`tabHPcDisBlock init_a_visualPc ${Style.visual}`}
                  >
                    <Img
                      className="init_a_imgPc"
                      fluid={data.MainVisualPc.childImageSharp.fluid}
                      alt="KYOTO EXPERIMENT"
                    />
                  </div>
                  <div className={Style.left}>
                    <div className={Style.info}>
                      <p className={Style.category}>ウェブサイト構築・運用</p>
                      <h3>KYOTO EXPERIMENT</h3>
                      <p className={Style.description}>世界各地の先鋭的な舞台芸術を紹介してきた京都発の国際舞台芸術祭「KYOTO EXPERIMENT」のサイトを2017年より担当しています。</p>
                      <div className={`pcDisBlock ${Style.solution}`}>
                        <p>Solution</p>
                        <p>コンセプト設計／コンテンツ企画／情報設計／アートディレクション／サイトデザイン／HTML・CSSコーディング／システム要件定義／プロジェクト管理／多言語対応：日本語、英語</p>
                      </div>
                      <div className={`viewMore ${Style.viewMore}`}>
                        <svg
                          className="arrow"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 125 20.4"
                        >
                          <line x1="0" y1="10.2" x2="200" y2="10.2" />
                          <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className={Style.device}>
                    <Img
                      className="spTabVDisBlock"
                      fluid={data.DeviceSp.childImageSharp.fluid}
                      alt="KYOTO EXPERIMENT 京都国際舞台芸術祭 2019 サイト構築・運用"
                    />
                    <Img
                      className="tabHPcDisBlock"
                      fluid={data.DevicePc.childImageSharp.fluid}
                      alt="KYOTO EXPERIMENT 京都国際舞台芸術祭 2019 サイト構築・運用"
                    />
                  </div>
                </div>
              </div>
            </TransitionLink>
          </Observer>
        )}
      />
    );
  }
}

PublishedKex.propTypes = {
  transition: PropTypes.object.isRequired,
};

export default PublishedKex;
